import { Schema } from 'rsuite';
import { CreateAccWithSocial, SocialAuthResponse } from './interfaces';

const { StringType, DateType, NumberType } = Schema.Types;

export const CreateAccountSchema = Schema.Model({
  FirstName: StringType().isRequired('This field is required.'),
  LastName: StringType().isRequired('This field is required.'),
  GenderId: NumberType().isRequired('This field is required.'),
  DOB: DateType()
    .isRequired('Please enter a valid date of birth (i.e. mm/dd/yyyy)')
    .max(new Date(), 'Date of birth cannot be a future date'),
  Email: StringType().isEmail('Please enter a valid email address.').isRequired('This field is required.'),
  Phone: StringType()
    .pattern(/^(\()?\d{3}(\))?(-|\s)?\d{3}(-|\s)\d{4}$/, 'Please enter a valid phone number.')
    .isRequired('This field is required.'),
  Password: StringType('Please enter 8-digit identifier')
    .isRequired('This field is required.')
    .minLength(8, 'The field cannot be less than 8 characters')
    .containsNumber('Must contain numbers')
    .containsUppercaseLetter('Must contain 1 capital letter')
    .addRule(value => {
      return /[!%&'()*+./;<=>?\\,/:#@"\\[\]_\u007B-\u00BF-]/.test(value);
    }, 'Must contain 1 special character'),
});

export const initData = {
  FirstName: '',
  LastName: '',
  GenderId: null,
  DOB: '',
  Email: '',
  Phone: '',
  Password: '',
  TermsAgreed: 0,
  AllowTexts: true,
};

export const texts = {
  accCreationSuccess: {
    title: 'Account successfully created',
    content: 'Now you can start scheduling your first Scan.',
    buttonText: "Let's start",
  },

  accCreationErrorSocial: {
    title: 'Error',
    content: 'The user with such Email already registered. Please log in using your credential and connect this social with your account',
    buttonText: 'Close',
  },

  accCreationError: {
    title: 'Error',
    content: 'The user with such Email already registered. Please log in using your credential',
    buttonText: 'Close',
  },
};

export const prepareSocialData = (data: SocialAuthResponse): CreateAccWithSocial => {
  return {
    FirstName: data._profile.firstName,
    LastName: data._profile.lastName,
    Email: data._profile.email,
    ProviderID: data._profile.id,
    ProviderName: data._provider,
  };
};
